<template>
  <div class="contain">
    <navbar :title="type ? '修改支付密码' : '设置支付密码'"></navbar>
    <div class="sms-block">
      <div class="sms-title">验证手机号码</div>
      <div>
        <div class="cell">
          <van-field
            v-model="mobileTxt"
            label="手机号"
            readonly
          />
        </div>
        <div class="cell">
          <van-field
            v-model="code"
            center
            label="验证码"
            placeholder="填写验证码"
          >
            <template #button>
              <div :class="{'sms-btn':true, 'sms-btn-disabled': sending}" @click="send">{{sendTxt}}</div>
            </template>
          </van-field>
        </div>
      </div>
      <div class="footer">
        <div class="add-btn" @click="next()">下一步</div>
      </div>
    </div>
    <password-popup v-model="openPopup" :type="type" :code="checkCode"></password-popup>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import passwordPopup from "@/components/password-popup.vue";
import {replaceMobile} from '@/lib/utils.js'
import {send, checkSmsCode} from '@/api/index.js'
import { mapGetters } from 'vuex'

export default {
  name: 'setPassword',
  components: {
    Navbar,
    passwordPopup
  },
  data() {
    return {
      mobile: '',
      code: '',
      sendTxt: '获取验证码',
      time: 60,
      sending: false,
      timer: null,
      openPopup: false,
      type: 0,
      checkCode: ''
    }
  },
  created() {
    this.type = parseInt(this.$route.query.type) || 0
  },
  computed: {
    ...mapGetters({user: 'user/getUser'}),
    mobileTxt() {
      return this.getMobile(this.user ? this.user.mobilephone : '');
    }
  },
  methods: {
    send() {
      if (this.sending) return false
      this.sending = true
      send()
        .then(res => {
          if (res.code == 0) {
            this.sendTxt = '已发送 (' + this.time + ')'
            this.timer = setInterval(() => {
              --this.time
              if (parseInt(this.time) > 0) {
                this.sendTxt = '已发送 (' + this.time + ')'
              } else {
                clearInterval(this.timer)
                this.sending = false
                this.sendTxt = '获取验证码'
                this.time = 60
              }
            }, 1000)
          }else {
            this.sending = false
            this.$toast.fail(res.msg)
          }
        }).catch(err => {
          console.log(err)
          this.sending = false
        })
    },
    next() {
      if (this.code == '') {
        return this.$toast('请输入验证码')
      }
      checkSmsCode({
        mobilephone: this.mobile,
        validation: this.code
      }).then(res => {
        if (res.code == 0) {
          this.checkCode = res.data.code
          this.openPopup = true
        } else {
          this.$toast.fail(res.msg)
        }
      }).catch(err => {
        console.log(err)
        this.$toast.fail('验证码错误')
      })
    },
    getMobile(mobile) {
      if (mobile) {
        this.mobile = mobile
        return replaceMobile(mobile)
      } 
      return mobile
    }
  }
}
</script>

<style lang="scss" scoped>
.contain{
  height: 100vh;
  background: #fff;
}
.sms-block{
  padding: 0 20px;

  .sms-title{
    margin-top: 30px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #4F4F4F;
  }

  .cell{
    margin-top: 30px;

    .sms-btn{
      width: 158px;
      height: 58px;
      background: #1AA3FF;
      border-radius: 41px;
      font-size: 24px;
      color: #fff;
      line-height: 58px;
      text-align: center;
    }
    .sms-btn-disabled{
      background: #E7EDF1;
      color: #666666;
    }
  }
  .van-cell{
    padding: 25px 39px 25px 30px;
    border-radius: 10px;
    
  }
  :deep(.van-field__label){
    width: 130px;
    font-size: 36px;
    color: #666;
    font-weight: 500;
  }
  :deep(.van-cell__value){
    font-size: 36px;
    color: #666;
  }
  .footer{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 29px 30px;
    box-sizing: border-box;
    @include safe-area-inset-bottom('30px');
    .add-btn{
      height: 88px;
      background: #1AA3FF;
      color: #fff;
      border-radius: 41px; 
      font-size: 36px;
      line-height: 88px;
      text-align: center;
      .add{
        display: inline-block;
        margin-left: 24px;
      }
    }
  }
}
</style>