<template>
  <van-popup v-model="show" position="right" style="width:100%;height:100%" @open="open">
    <div class="contain">
      <navbar :title="type ? '修改支付密码' : '设置支付密码'" :leftArrow="true"></navbar>
      <div class="sub-title">{{subTitles[status]}}</div>
      <div class="pass">
        <van-password-input
          :value="number"
          :gutter="$px2vw(20)"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
      </div>
      <div class="submit" v-if="status == 1" @click="submit">完成</div>
      <van-number-keyboard
        v-model="number"
        :show="true"
        maxlength="6"
        @blur="showKeyboard = false"
        @input="getValue"
      />
    </div>
  </van-popup>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import {setPassword} from '@/api/index'
import AES from '@/lib/aes.js'

export default {
  components: {
    Navbar
  },
  props:{
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      number: '',
      showKeyboard: false,
      subTitles: ['请设置支付密码，用于支付验证', '请再次填写以确认'],
      status: 0,
      form: {}
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    clickLeft() {
      if (this.status == 0) {
        this.show = false
      } else {
        this.status = 0
        this.number = this.form.password
      }
    },
    open() {
      this.number = '',
      this.form = {
        password: '',
        rePassword: ''
      }
      this.status = 0
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.listernerBack, false);
      }
    },
    getValue(v) {
      setTimeout(() => {
        if (this.number.length == 6) {
          if (this.status == 0) {
            this.form.password = this.number
            this.status = 1
            this.number = ''
          } else {
            this.form.rePassword = this.number
          }
        }
      }, 50)
    },
    submit() {
      if (this.form.password != this.form.rePassword) {
        return this.$toast('两次密码不一样')
      }
      var form = {...this.form}
      form.code = this.code
      form.password = AES.encrypt(form.password + '-' + parseInt(new Date().getTime()/1000));
      form.rePassword = form.password
      setPassword(form)
        .then(res => {
          if (res.code ==0) {
            this.$toast('操作成功！');
            this.$router.push('/my')
          } else {
            this.$toast(res.msg)
          }
        }).catch(err => {
          console.log(err)
          this.$toast('操作失败！')
        })
    },
    listernerBack() {
      this.clickLeft()
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.listernerBack, false);
  }
}
</script>

<style lang="scss" scoped>
.contain{
  .sub-title{
    padding: 40px 0 70px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #4F4F4F;
  }
  .pass{
    padding: 0 32px;
  }
  .submit{
    margin: 72px auto 0;
    width: 628px;
    height: 88px;
    background: #1AA3FF;
    border-radius: 41px;
    line-height: 88px;
    color: #fff;
    text-align: center;
    font-size: 36px;
  }
}
</style>